import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero/contact"

const KontaktPage = () => (
  <Layout>
    <Seo title="Unsere Erfahrung" />
    <Hero />
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-4 is-offset-4">
            <table className="table">
              <tbody>
                <tr>
                  <td>
                    <h4 className="m-0">Phone</h4>
                  </td>
                  <td>+49 511 33 85 99 72</td>
                </tr>
                <tr>
                  <td>
                    <h4 className="m-0">Mail</h4>
                  </td>
                  <td>
                    <a href="mailto:info@clavicon.com">info@clavicon.com</a>
                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: `top` }}>
                    <h4 className="m-0">
                      Adress
                    </h4>
                  </td>
                  <td>
                    Prinzenstraße 14
                    <br /> 30159 Hannover<br />
                    Germany
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default KontaktPage
